// library
import axios from "axios"

// interfaces
import {
    CreateLeaseItemInterface,
} from "./Interfaces";


// const url = 'https://bohome.services/api/v1'; // 본 서버
const url = 'https://test.bohome.services/api/v1';	// 테스트 서버


export const tokenVerifyAPI = async(data : any, headers : {token : string}) => {
    const getUsetData = await axios({
        method : "post",
        url : url + "/subscriber/tenant/token/verify",
        data : data,
        headers : headers
    })

    return getUsetData
}

export const GetLeaseItems = async(headers : {token : string}, page : number) => {
    const getLeaseItem = await axios({
        method : "get",
        url : url + "/admin/master/lease-items",
        params : {page : page, limit : 10},
        headers : headers
    })

    return getLeaseItem
}

export const GetReceiptItems = async(headers : {token : string}, page : number) => {
    const getReceiptItem = await axios({
        method : "get",
        url : url + "/admin/master/lease-items/payments",
        params : {page : page, limit : 10},
        headers : headers
    })

    return getReceiptItem
}

export const GetBanks = async() => {
	const getBanks : any = axios({
		method : "get",
		url : url + '/subscriber/tenant/banks'
	})

	return getBanks
}

export const CreateLeaseItem = async(headers : {token : string}, data : CreateLeaseItemInterface) => {
    const createLeaseItem = await axios({
        method : "post",
        url : url + "/admin/master/lease-item",
        data : data,
        headers : headers
    })

    return createLeaseItem
}

export const GetLeaseItemDetail = async(headers : {token : string}, unique_number : number) => {
    const getLeaseItemDetail = await axios({
        method : "get",
        url : url + "/admin/master/lease-items/" + unique_number,
        headers : headers
    })

    return getLeaseItemDetail
}

export const UpdateLeaseItem = async(headers : {token : string}, unique_number : number, data : CreateLeaseItemInterface) => {
    const updateLeaseItem = await axios({
        method : "put",
        url : url + "/admin/master/lease-items/" + unique_number,
        data : data,
        headers : headers
    })

    return updateLeaseItem
}

export const SearchLeaseItems = async(headers : {token : string}, keyword : string, page : number) => {
    const searchLeaseItem = await axios({
        method : "get",
        url : url + "/admin/master/lease-items",
        params : {search : keyword, page : page, limit : 10},
        headers : headers
    })

    return searchLeaseItem
}

export const SearchReceiptItems = async(headers : {token : string}, keyword : string, page : number) => {
    const searchLeaseItem = await axios({
        method : "get",
        url : url + "/admin/master/lease-items/payments",
        params : {search : keyword, page : page, limit : 10},
        headers : headers
    })

    return searchLeaseItem
}