// react
import React from "react";

// material ui
import { 
    Box
} from "@mui/material";

// library

// components

// screens

// styles
import { colors, fonts, styles, components } from "../styles";

// api

// images


interface props {
    distance : number
}

export const Spacer : React.FC<props> = (props) => {
    return (
        <Box sx = {{width : props.distance, height : props.distance}}/>
    )
}