// react
import React from "react";

// material ui
import {
    Container,
    Box,
    Typography,
    Snackbar,
    Alert,
    IconButton,
} from "@mui/material";

// library

// components

// pages

// interfaces

// styles
import { colors, fonts, styles, components } from "../styles";

// api

// images
import { ErrorOutline, CheckCircleOutline, Close, WarningAmberOutlined } from "@mui/icons-material";


interface props {
    isVisible : boolean
    type : "error" | "warn" | "success"
    title : string
    onClose() : void
    onPressCloseButton?() : void
}

export const ToastMessage : React.FC<props> = (props) => {
    const renderIcon = () => {
        switch (props.type) {
            case "error" : 
                return (
                    <Box
                        sx = {[styles.horizonCenter, styles.verticalCenter, {p : 16, bgcolor : colors.red}]}
                    >
                        <ErrorOutline sx = {{width : 24, height : 24, color : colors.white}}/>
                    </Box>
                )

            case "warn" : 
                return (
                    <Box
                        sx = {[styles.horizonCenter, styles.verticalCenter, {p : 16, bgcolor : colors.orange}]}
                    >
                        <WarningAmberOutlined sx = {{width : 24, height : 24, color : colors.white}}/>
                    </Box>
                )

            case "success" : 
                return (
                    <Box
                        sx = {[styles.horizonCenter, styles.verticalCenter, {p : 16, bgcolor : colors.green}]}
                    >
                        <CheckCircleOutline sx = {{width : 24, height : 24, color : colors.white}}/>
                    </Box>
                )
        }
    }

    return (
        <Snackbar
            open = {props.isVisible}
            autoHideDuration = {2800}
            onClose = {props.onClose}
            anchorOrigin = {{vertical: 'top', horizontal: 'center'}}
        >
            <Box
                sx = {[styles.alignHorizon, {bgcolor : colors.white}]}
            >
                {renderIcon()}
                <Box
                    sx = {[styles.horizonCenter, styles.verticalCenter, {p : 16, bgcolor : colors.white}]}
                >
                    <Typography>{props.title}</Typography>
                </Box>
                <IconButton
                    sx = {[styles.horizonCenter, styles.verticalCenter, {p : 16, bgcolor : colors.white}]}
                    onClick = {props.onPressCloseButton}
                >
                    <Close sx = {{width : 24, height : 24, color : colors.grey2}}/>
                </IconButton>
            </Box>
        </Snackbar>
    )
}