// react
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// material ui
import {
    Container,
    Box,
    Typography,
    Button,
    InputBase,
    CardActionArea,
    FormControl,
    TextField,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Pagination,
    Modal,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// library
import moment from "moment";
import { auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";

// components
import { Spacer } from "../components/Spacer";
import { ToastMessage } from "../components/ToastMessage";

// pages

// interfaces
import { GetLeaseItemInterface, GetReceiptInterface } from "../utils/Interfaces";

// styles
import { colors, fonts, styles, components } from "../styles";

// api
import { tokenVerifyAPI, GetLeaseItems, GetReceiptItems, SearchLeaseItems, SearchReceiptItems } from "../utils/APIs";

// images
import { ReactComponent as BOHOME_LOGO } from "../assets/images/bohome_logo.svg"
import { RotateRight, ExpandMore, WarningAmberOutlined } from "@mui/icons-material";

// utils


const translator : any = {
    leaseId : "임대고유번호",
    landlordName : "임대인명",
    purchaseDay : "매입일(청구일)",
    address : "주소",
    registDay : "등록일",
    total : "검색어"
}

const MainPage = () => {
    const navigation = useNavigate()
    const { state } = useLocation()

    const [isLoading, setIsLoading] = useState(false)

    // get user data
    const [firebaseUserData, setFirebaseUserData] = useState<any>()
    const [userData, setUserData] = useState<any>()

    const getUserData = async(userData : any) => {
        const userToken = await userData.getIdToken();
		const headers = {token: userToken};
		const result = await tokenVerifyAPI(null, headers);

        if (result.status === 200) {
            setUserData(result.data.data)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setFirebaseUserData(user)
                getUserData(user)
            }
            else {
                navigation("/")
            }
        });
        setIsLoading(false)
    }, [])
    
    const [isLogoutConfirmModalVisible, setIsLogoutConfirmModalVisible] = useState(false)
    const [houseData, setHouseData] = useState<GetLeaseItemInterface[]>([])
    const [houseDataPages, setHouseDataPages] = useState(0)
    const [receiptData, setReceptData] = useState<GetReceiptInterface[]>([])
    const [receiptDataPages, setReceiptDataPages] = useState(0)

    const [currentPage, setCurrentPage] = useState(1)

    // 검색 결과 불러오기
    const getData = async(page : number) => {
        setIsLoading(true)

        const userToken = await firebaseUserData?.getIdToken();
        const headers = {token: userToken};
        const result = await tokenVerifyAPI(null, headers);

        if (result.status === 200) {
            if (checkData) {
                switch (checkTitle) {
                    case "house" : {
                        const searchResult = await SearchLeaseItems(headers, typeof(checkData) === "string" ? checkData : moment(checkData).format("YYYYMMDD"), currentPage)
    
                        if(searchResult.status === 200) {
                            setHouseData(searchResult.data.data)
                            if (searchResult.data.page_info.total) {
                                if (searchResult.data.page_info.total % 10 === 0){
                                    setHouseDataPages(Math.floor(searchResult.data.page_info.total / 10))
                                }
                                else {
                                    setHouseDataPages(Math.floor(searchResult.data.page_info.total / 10) + 1)
                                }
                            }
                            else {
                                setHouseDataPages(1)
                            }
                        }
                        break
                    }
    
                    case "receipt" : {
                        const searchResult = await SearchReceiptItems(headers, typeof(checkData) === "string" ? checkData : moment(checkData).format("YYYYMMDD"), currentPage)
    
                        if(searchResult.status === 200) {
                            setReceptData(searchResult.data.data)
                            if (searchResult.data.page_info.total) {
                                if (searchResult.data.page_info.total % 10 === 0){
                                    setReceiptDataPages(Math.floor(searchResult.data.page_info.total / 10))
                                }
                                else {
                                    setReceiptDataPages(Math.floor(searchResult.data.page_info.total / 10) + 1)
                                }
                            }
                            else {
                                setReceiptDataPages(1)
                            }
                        }
                        break
                    }
                }
            }
            else {
                const tmpHouseData : any = await GetLeaseItems(headers, page)
                if (tmpHouseData?.status === 200) {
                    setHouseData(tmpHouseData.data.data)
                    if (tmpHouseData.data.page_info.total % 10 === 0){
                        setHouseDataPages(Math.floor(tmpHouseData.data.page_info.total / 10))
                    }
                    else {
                        setHouseDataPages(Math.floor(tmpHouseData.data.page_info.total / 10) + 1)
                    }
                }

                const tmpReceiptData : any = await GetReceiptItems(headers, page)
                if (tmpReceiptData?.status === 200) {
                    setReceptData(tmpReceiptData.data.data)
                    if (tmpReceiptData.data.page_info.total % 10 === 0){
                        setReceiptDataPages(Math.floor(tmpReceiptData.data.page_info.total / 10))
                    }
                    else {
                        setReceiptDataPages(Math.floor(tmpReceiptData.data.page_info.total / 10) + 1)
                    }
                }
            }
        }
        
        setIsLoading(false)
    }

    // 페이지 변경시 데이터 불러오기
    useEffect(() => {
        if (firebaseUserData) {
            getData(currentPage)
        }
    }, [firebaseUserData, currentPage])

    const mainBoxRef : any = useRef()

    const onClickAddLease = () => {
        navigation("/MainPage/LeaseAddPage")
    }

    const [checkTitle, setCheckTitle] = useState<"house"|"receipt">("house")

    const onChangeChecktitle = (data : "house" | "receipt") => {
        setCurrentPage(1)
        setCheckTitle(data)
        setCheckTag("total")
        getData(1)
    }

    const [checkTag, setCheckTag] = useState<"total" | "purchaseDay" | "registDay">("total")
    const [checkData, setCheckData] = useState<string | Date | null>("")

    const [isToastMessageVisible, setIsToastMessageVisible] = useState(false)

    useEffect(() => {
        setCheckData("")
    }, [checkTitle, checkTag])

    const onChangeCheckTag = (event : any) => {
        setCheckTag(event.target.value)
    }

    const onChangeCheckData = (event : any) => {
        setCheckData(event.target.value)
    }

    const [toastType, setToastType] = useState<"error" | "warn" | "success">("success")
    const [toastMessage, setToastMessage] = useState("")

    const onClickSearchButton = async() => {
        if (!checkData) {
            getData(1)
            setToastType("error")
            setToastMessage("검색어를 입력해주세요.")
            setIsToastMessageVisible(true)
            return
        }

        setIsLoading(true)

        setCurrentPage(1)

        const userToken = await firebaseUserData?.getIdToken();
		const headers = {token: userToken};
		const result = await tokenVerifyAPI(null, headers);

        if (result.status === 200) {
            switch (checkTitle) {
                case "house" : {
                    const searchResult = await SearchLeaseItems(headers, typeof(checkData) === "string" ? checkData : moment(checkData).format("YYYYMMDD"), 1)

                    if(searchResult.status === 200) {
                        setHouseData(searchResult.data.data)
                        if (searchResult.data.page_info.total) {
                            if (searchResult.data.page_info.total % 10 === 0){
                                setHouseDataPages(Math.floor(searchResult.data.page_info.total / 10))
                            }
                            else {
                                setHouseDataPages(Math.floor(searchResult.data.page_info.total / 10) + 1)
                            }
                        }
                        else {
                            setHouseDataPages(1)
                        }
                    }
                    break
                }

                case "receipt" : {
                    const searchResult = await SearchReceiptItems(headers, typeof(checkData) === "string" ? checkData : moment(checkData).format("YYYYMMDD"), 1)

                    if(searchResult.status === 200) {
                        setReceptData(searchResult.data.data)
                        if (searchResult.data.page_info.total) {
                            if (searchResult.data.page_info.total % 10 === 0){
                                setReceiptDataPages(Math.floor(searchResult.data.page_info.total / 10))
                            }
                            else {
                                setReceiptDataPages(Math.floor(searchResult.data.page_info.total / 10) + 1)
                            }
                        }
                        else {
                            setReceiptDataPages(1)
                        }
                    }
                    break
                }
            }
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if (state?.isAddLeaseCancel) {
            setToastType("warn")
            setToastMessage("임대 물건 등록이 취소되었습니다.")
            setIsToastMessageVisible(true)
        }
        if (state?.isAddLeaseSuccess) {
            setToastType("success")
            setToastMessage("임대 물건이 정상적으로 등록되었습니다.")
            setIsToastMessageVisible(true)
        }
    }, [state])

    const onPressLogout = () => {
        auth.signOut()
        navigation("/")
    }

    const onChangeCurrentPage = (page : number) => {
        setCurrentPage(page)
    }

    const renderSearchArea = () => {
        switch (checkTitle) {
            case "house" :
                return (
                    <Box
                        sx = {[styles.alignHorizon, {padding : 40}]}
                    >
                        <FormControl
                            fullWidth
                            sx = {[styles.verticalCenter, {flex : 3}]}
                        >
                            <Select
                                sx = {[fonts.normal_20, {height : 100}]}
                                value = {checkTag}
                                onChange = {(event) => onChangeCheckTag(event)}
                            >
                                <MenuItem value = {"total"}>{translator["leaseId"]}/{translator["landlordName"]}/{translator["address"]}</MenuItem>
                                <MenuItem value = {"registDay"}>{translator["registDay"]}</MenuItem>
                            </Select>
                        </FormControl>
                        <Spacer distance = {40}/>

                        <Box
                            sx = {[styles.horizonCenter, styles.verticalCenter, styles.spaceBetween, {flex : 5, height : 100, border : 1, borderColor : colors.grey2, borderRadius : 1}]}
                        >
                            {
                                checkTag === "registDay" ?
                                    <Box sx = {{width : "78%"}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value = {checkData}
                                                onChange = {(date : any) => date && setCheckData(date.toDate())}
                                                renderInput = {(params) =>
                                                    <TextField
                                                        {...params}
                                                        onKeyDown = {(data) => data.key === "Enter" && onClickSearchButton()}
                                                        sx = {[
                                                            components.leaseDataInputSmall,
                                                            {
                                                                "& .MuiOutlinedInput-input" : {
                                                                    fontFamily : "regular",
                                                                    fontSize : 20,
                                                                    color : colors.black,
                                                                },
                                                            },
                                                            {
                                                                pl : 32,
                                                            }
                                                        ]}
                                                    />
                                                }
                                                inputFormat = {"YYYY/MM/DD"}
                                            />
                                        </LocalizationProvider>
                                    </Box> :
                                    <InputBase
                                        value = {checkData}
                                        sx = {[fonts.normal_20, {width : "78%", height : "100%", pl : 32}]}
                                        placeholder = {translator[checkTag] + " 입력"}
                                        onChange = {(data) => onChangeCheckData(data)}
                                        onKeyDown = {(data) => data.key === "Enter" && onClickSearchButton()}
                                    />
                            }
                            <Spacer distance = {16}/>
                            
                            <Button
                                variant = {"contained"}
                                sx = {{width : "20%", mr : 32, bgcolor : "#ECF1FF", ":hover" : {bgcolor : colors.white}}}
                                onClick = {onClickSearchButton}
                            >
                                <Typography sx = {[fonts.bold_24, {color : colors.main}]}>검색</Typography>
                            </Button>
                        </Box>
                    </Box>
                )

            case "receipt" :
                return (
                    <Box
                        sx = {[styles.alignHorizon, {padding : 40}]}
                    >
                        <FormControl
                            fullWidth
                            sx = {[styles.verticalCenter, {flex : 3}]}
                        >
                            <Select
                                sx = {[fonts.normal_20, {height : 100}]}
                                value = {checkTag}
                                onChange = {(event) => onChangeCheckTag(event)}
                            >
                                <MenuItem value = {"total"}>{translator["leaseId"]}/{translator["landlordName"]}/{translator["address"]}</MenuItem>
                                <MenuItem value = {"purchaseDay"}>{translator["purchaseDay"]}</MenuItem>
                                <MenuItem value = {"registDay"}>{translator["registDay"]}</MenuItem>
                            </Select>
                        </FormControl>
                        <Spacer distance = {40}/>

                        <Box
                            sx = {[styles.horizonCenter, styles.verticalCenter, styles.spaceBetween, {flex : 5, height : 100, border : 1, borderColor : colors.grey2, borderRadius : 1}]}
                        >
                            {
                                checkTag === "registDay" || checkTag === "purchaseDay" ?
                                    <Box sx = {{width : "78%"}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value = {checkData}
                                                onChange = {(date : any) => date && setCheckData(date.toDate())}
                                                renderInput = {(params) =>
                                                    <TextField
                                                        {...params}
                                                        onKeyDown = {(data) => data.key === "Enter" && onClickSearchButton()}
                                                        sx = {[
                                                            components.leaseDataInputSmall,
                                                            {
                                                                "& .MuiOutlinedInput-input" : {
                                                                    fontFamily : "regular",
                                                                    fontSize : 20,
                                                                    color : colors.black,
                                                                },
                                                            },
                                                            {
                                                                pl : 32,
                                                            }
                                                        ]}
                                                    />
                                                }
                                                inputFormat = {"YYYY/MM/DD"}
                                            />
                                        </LocalizationProvider>
                                    </Box> :
                                    <InputBase
                                        value = {checkData}
                                        sx = {[fonts.normal_20, {width : "78%", height : "100%", pl : 32}]}
                                        placeholder = {translator[checkTag] + " 입력"}
                                        onChange = {(data) => onChangeCheckData(data)}
                                        onKeyDown = {(data) => data.key === "Enter" && onClickSearchButton()}
                                    />
                            }
                            <Spacer distance = {16}/>
                            
                            <Button
                                variant = {"contained"}
                                sx = {{width : "20%", mr : 32, bgcolor : "#ECF1FF", ":hover" : {bgcolor : colors.white}}}
                                onClick = {onClickSearchButton}
                            >
                                <Typography sx = {[fonts.bold_24, {color : colors.main}]}>검색</Typography>
                            </Button>
                        </Box>
                    </Box>
                )
        }
    }

    const renderTableBody = () => {
        switch (checkTitle) {
            case "house" : {
                return (
                    houseData.map((data, idx) => 
                        <TableRow
                            key = {idx}
                            onClick = {() => navigation("/MainPage/LeaseDetailPage", {state : {unique_number : data.unique_number}})}
                            sx = {{":hover" : {bgcolor : colors.grey0}}}
                        >
                            <TableCell sx = {{width : "20%"}}>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{data.unique_number}</Typography>
                            </TableCell>

                            <TableCell sx = {{width : "18%"}}>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{moment(data.createdAt).format("YYYY-MM-DD")}</Typography>
                            </TableCell>

                            <TableCell sx = {{width : "18%"}}>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{data.ceo}</Typography>
                            </TableCell>

                            <TableCell>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{data.address}</Typography>
                            </TableCell>
                        </TableRow>
                    )
                )
            }
        
        case "receipt" :
            {
                return (
                    receiptData.map((data, idx) => 
                        <TableRow
                            key = {idx}
                        >
                            <TableCell sx = {{width : "20%"}}>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{data.unique_number}</Typography>
                            </TableCell>

                            <TableCell sx = {{width : "25%"}}>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{data.rent_amount}</Typography>
                            </TableCell>

                            <TableCell>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{moment(data.payment.requested_date).format("YYYY-MM-DD")}</Typography>
                            </TableCell>

                            <TableCell sx = {{width : "25%"}}>
                                <Typography sx = {[fonts.normal_16, {color : colors.black}]}>{data.payment.acquire_yn === "Y" ? "Yes" : "No"}</Typography>
                            </TableCell>
                        </TableRow>
                    )
                )
            }
        }
    }

    return (
        <Box
            ref = {mainBoxRef}
        >
            <Box
                sx = {[styles.root, styles.alignVertical, {minHeight : "100vh"}]}
            >
                {/* 상단 로고 ~ 검색 영역 */}
                <Box
                    sx = {[{bgcolor : colors.main}]}
                >
                    <Box sx = {[styles.alignHorizon, styles.horizonCenter, styles.verticalEnd]}>
                        <Button
                            onClick = {() => {
                                setToastType("error")
                                setToastMessage("기능 준비중입니다.")
                                setIsToastMessageVisible(true)
                            }}
                        >
                            <Typography sx = {[fonts.normal_20, {color : colors.white}]}>{userData?.email}</Typography>
                            <ExpandMore sx = {{width : 36, height : 36, color : colors.white}}/>
                        </Button>
                        <Spacer distance={18}/>

                        <Typography sx = {[fonts.normal_20, {color : colors.white}]}>|</Typography>
                        <Spacer distance={24}/>

                        <Button
                            onClick = {() => setIsLogoutConfirmModalVisible(true)}
                        >
                            <Typography sx = {[fonts.normal_20, {color : colors.white}]}>로그아웃</Typography>
                        </Button>
                        <Spacer distance={24}/>
                    </Box>
                    <Box sx = {{flex : 1, width : "100%", height : 1, border : 1, borderColor : colors.white}}/>

                    <Container
                        fixed
                    >
                        <Spacer distance = {124}/>
                        <Box>
                            {/* 상단 로고, 물건 등록 버튼 */}
                            <Box
                                sx = {[styles.spaceBetween]}
                            >
                                <Box
                                    sx = {[styles.alignHorizon, styles.horizonEnd]}
                                >
                                    <BOHOME_LOGO width = {192} height = {58}/>
                                    <Spacer distance = {14}/>
                                    <Typography sx = {[fonts.bold_24, {fontSize : 28, color : colors.white}]}>| 임대물건 등록</Typography>
                                </Box>

                                {/* 물건 등록 버튼 */}
                                <Button
                                    variant = {"contained"}
                                    sx = {[{width : 206, height : 58, bgcolor : colors.white, ":hover" : {bgcolor : colors.white}}]}
                                    onClick = {onClickAddLease}
                                >
                                    <Typography sx = {[fonts.bold_24, {color : colors.main}]}>물건 등록</Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Spacer distance = {52}/>

                        {/* 물건 조회, 영수증 조회 탭 */}
                        <Box
                            sx = {[styles.alignHorizon]}
                        >
                            <CardActionArea
                                sx = {[
                                    styles.horizonCenter,
                                    styles.verticalCenter,
                                    components.headerTab,
                                    checkTitle === "house" ? 
                                        {bgcolor : colors.white} :
                                        {bgcolor : colors.grey1}
                                ]}
                                onClick = {() => onChangeChecktitle("house")}
                            >
                                <Typography sx = {[
                                    fonts.normal_24,
                                    checkTitle === "house"?
                                        {color : colors.main} :
                                        {color : colors.grey2}
                                ]}>
                                    물건 조회
                                </Typography>
                            </CardActionArea>
                            <Spacer distance = {12}/>

                            <CardActionArea
                                sx = {[
                                    styles.horizonCenter,
                                    styles.verticalCenter,
                                    components.headerTab,
                                    checkTitle === "receipt" ? 
                                        {bgcolor : colors.white} :
                                        {bgcolor : colors.grey1}
                                ]}
                                onClick = {() => onChangeChecktitle("receipt")}
                            >
                                <Typography sx = {[
                                    fonts.normal_24,
                                    checkTitle === "receipt"?
                                        {color : colors.main} :
                                        {color : colors.grey2}
                                ]}>
                                    영수증 조회
                                </Typography>
                            </CardActionArea>
                        </Box>
                        
                        {/* 검색 타입, 검색어 입력 */}
                        <Box
                            sx = {[{
                                width : "100%",
                                bgcolor : colors.white,
                                borderRadius : 2,
                                borderTopLeftRadius : 0
                            }]}
                        >
                            {renderSearchArea()}
                        </Box>
                        <Spacer distance = {40}/>
                    </Container>
                </Box>

                {/* 검색영역 이후 표 */}
                <Box
                    sx = {[{flex : 1, bgcolor : colors.grey0}]}
                >
                    <Container
                        fixed
                        sx = {[styles.alignVertical, styles.horizonCenter, {flex : 1}]}
                    >
                        <Spacer distance = {40}/>

                        <Box
                            sx = {{p : 40, bgcolor : colors.white, width : "100%"}}
                        >
                            <Table>
                                <TableHead
                                    sx = {{bgcolor : colors.grey0}}
                                >
                                    {
                                        checkTitle === "house" ?
                                            <TableRow>
                                                <TableCell sx = {[{width : "20%"}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>임대고유번호</Typography>
                                                </TableCell>
                                                <TableCell sx = {[{width : "18%"}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>등록일</Typography>
                                                </TableCell>
                                                <TableCell sx = {[{width : "18%"}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>임대인명</Typography>
                                                </TableCell>
                                                <TableCell sx = {[{}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>주소</Typography>
                                                </TableCell>
                                            </TableRow> :
                                            <TableRow>
                                                <TableCell sx = {[{width : "20%"}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>임대고유번호</Typography>
                                                </TableCell>
                                                <TableCell sx = {[{width : "25%"}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>임대금액</Typography>
                                                </TableCell>
                                                <TableCell sx = {[{}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>매입일(청구일)</Typography>
                                                </TableCell>
                                                <TableCell sx = {[{width : "25%"}]}>
                                                    <Typography sx = {[fonts.normal_20, {color : colors.grey3}]}>청구여부(매입여부)</Typography>
                                                </TableCell>
                                            </TableRow>
                                    }
                                    
                                </TableHead>

                                {
                                    checkTitle === "house" ?
                                        houseData.length ?
                                            <TableBody
                                                sx = {{bgcolor : colors.white}}
                                            >
                                                {renderTableBody()}
                                            </TableBody> :
                                            <TableBody
                                                sx = {{bgcolor : colors.white}}
                                            >
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <Box sx = {[styles.verticalCenter]}>
                                                            <Typography sx = {[fonts.normal_18]}>데이터가 없습니다.</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody> :
                                        
                                        receiptData.length ?
                                            <TableBody
                                                sx = {{bgcolor : colors.white}}
                                            >
                                                {renderTableBody()}
                                            </TableBody> :
                                            <TableBody
                                                sx = {{bgcolor : colors.white}}
                                            >
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <Box sx = {[styles.verticalCenter]}>
                                                            <Typography sx = {[fonts.normal_18]}>데이터가 없습니다.</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                }
                            </Table>
                        </Box>
                        <Spacer distance = {72}/>

                        <Box
                            sx = {{bgcolor : colors.white, p : 8}}
                        >
                            <Pagination
                                count = {checkTitle === "house" ? houseDataPages : receiptDataPages}
                                shape = {"rounded"}
                                showFirstButton
                                showLastButton
                                hideNextButton
                                hidePrevButton
                                color = {"primary"}
                                onChange = {(event, page) => onChangeCurrentPage(page)}
                                page = {currentPage}
                            />
                        </Box>
                        <Spacer distance = {120}/>
                    </Container>
                </Box>

                <ToastMessage
                    isVisible = {isToastMessageVisible}
                    type = {toastType}
                    title = {toastMessage}
                    onClose = {() => setIsToastMessageVisible(false)}
                    onPressCloseButton = {() => setIsToastMessageVisible(false)}
                />
            </Box>

            {/* 로그아웃 확인 modal */}
            <Modal
                open = {isLogoutConfirmModalVisible}
            >
                <Box
                    sx = {{
                        position : "absolute",
                        top : "50%",
                        left : "50%",
                        transform : "translate(-50%, -50%)",
                        width : "60vw",
                        backgroundColor : "#fff",
                        p : 40
                    }}
                >
                    <Box sx = {[styles.alignHorizon, styles.horizonCenter]}>
                        <WarningAmberOutlined sx = {{width : 32, height : 32, color : colors.red}}/>
                        <Spacer distance = {16}/>
                        <Typography sx = {[fonts.bold_20]}>로그아웃 확인</Typography>
                    </Box>
                    <Spacer distance = {16}/>

                    <Box>
                        <Typography sx = {[fonts.normal_16]}>로그아웃 하시겠습니까?</Typography>
                    </Box>
                    <Spacer distance = {62}/>

                    <Box sx = {[styles.alignHorizon, styles.verticalEnd, {width : "100%"}]}>
                        <Button
                            variant = {"outlined"}
                            onClick = {() => setIsLogoutConfirmModalVisible(false)}
                        >
                            <Typography sx = {[fonts.normal_16]}>취소</Typography>
                        </Button>
                        <Spacer distance = {8}/>

                        <Button
                            variant = {"contained"}
                            onClick = {onPressLogout}
                        >
                            <Typography sx = {[fonts.normal_16, {color : colors.white}]}>로그아웃</Typography>
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* loading */}
            {
                isLoading &&
                    <Box>
                        <Box sx = {[{position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight, bgcolor : colors.black, opacity : 0.5}]}/>
                        <Box sx = {[styles.horizonCenter, styles.verticalCenter, {position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight}]}>
                            <Box sx = {[styles.alignVertical, styles.horizonCenter, styles.verticalCenter, {width : "40%", height : 180, bgcolor : colors.white}]}>
                                <Typography sx = {[fonts.bold_16]}>잠시만 기다려주세요.</Typography>
                                <Typography sx = {[fonts.normal_16]}>검색중...</Typography>
                                <Spacer distance = {24}/>
                                <RotateRight sx = {[
                                    {width : 42, height : 42, color : colors.green},
                                    {
                                        animation: "spin 2s linear infinite",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(0deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(360deg)",
                                            },
                                        },
                                    }
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
            }
        </Box>
    )
}

export default MainPage;