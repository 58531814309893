// react
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// material ui
import {
    Container,
    Box,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    ButtonGroup,
    Button,
    TextField,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    Modal,
    IconButton,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// library
import { auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useFormik, FormikProps } from "formik";
import DaumPostcode from 'react-daum-postcode';
import moment from "moment";

// components
import { Spacer } from "../components/Spacer";

// pages

// interfaces
import { CreateLeaseItemInterface, BanksType } from "../utils/Interfaces";

// styles
import { colors, fonts, styles, components } from "../styles";

// api
import { tokenVerifyAPI, GetBanks, UpdateLeaseItem } from "../utils/APIs";

// images
import { Close, ArrowBackIos, WarningAmberOutlined, Check, RotateRight } from "@mui/icons-material";

// utils
import { LeaseAddValidationSchema } from "../utils/ValidationSchema";
import translator from "../utils/Translator.json"


const Translator : any = translator.translator

const DayArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
const PhoneArr = ['010','011','016','017','019']

const LeaseUpdatePage = () => {
    const naviagtion = useNavigate()
    const { state } = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const mainBoxRef : any = useRef()

    // get user data
    const [firebaseUserData, setFirebaseUserData] = useState<any>()
    const [userData, setUserData] = useState<any>()

    const getUserData = async(userData : any) => {
        const userToken = await userData.getIdToken();
		const headers = {token: userToken};
		const result = await tokenVerifyAPI(null, headers);

        if (result.status === 200) {
            setUserData(result.data.data)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setFirebaseUserData(user)
                getUserData(user)
            }
        });
        setIsLoading(false)
    }, [])

    const leaseItemData = state.leaseItemData

    const formik : FormikProps<CreateLeaseItemInterface>  = useFormik<CreateLeaseItemInterface>({
        initialValues : {
            rent_type : leaseItemData.rent_type,
            rent_amount : leaseItemData.rent_amount,
            rent_start_day : leaseItemData.rent_start_day,
            rent_end_day : leaseItemData.rent_end_day,
            address : leaseItemData.address,
            detail_address : leaseItemData.detail_address,
            zip : leaseItemData.zip,
            pay_day : leaseItemData.pay_day,
            receive_type : leaseItemData.receive_type,
            ceo : leaseItemData.ceo,
            phone_one : leaseItemData.phone_one.slice(0, 3),
            phone_two : leaseItemData.phone_two.slice(3),
            biz_name : leaseItemData.biz_name,
            biz_no : leaseItemData.biz_no,
            bank_code : "",
            account_no : leaseItemData.account_no,
            account_holder : leaseItemData.account_holder,
        },
        onSubmit : values => {
            setIsAddConfirmModalVisible(true)
        },
        validateOnMount : true,
        validationSchema : LeaseAddValidationSchema
    })

    // textfield error state
    const [isRentAmountError, setIsRentAmountError] = useState(false)
    const [isRentStartDayError, setIsRentStartDayError] = useState(false)
    const [isRentEndDayError, setIsRentEndDayError] = useState(false)
    const [isRentDayIncorrect, setIsRentDayIncorrect] = useState(false)
    const [isAddressError, setIsAddressError] = useState(false)
    const [isZipError, setIsZipError] = useState(false)
    const [isPayDayError, setIsPayDayError] = useState(false)
    const [isCeoError, setIsCeoError] = useState(false)
    const [isPhoneTwoError, setIsPhoneTwoError] = useState(false)
    const [isBankCodeError, setIsBankCodeError] = useState(false)
    const [isAccountNoError, setIsAccountNoError] = useState(false)
    const [isAccountHolderError, setIsAccountHolderError] = useState(false)

    // modal state
    const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] = useState(false)
    const [isBackWarningModalVisible, setIsBackWarningModalVisible] = useState(false)
    
    // about bank
    const [bankList, setBankList] = useState<BanksType[]>([])

    useEffect(() => {
        const getBank = async() => {
            const tmpBank = await GetBanks()

            if (tmpBank.status === 200) {
                setBankList(tmpBank.data.data)
                formik.setFieldValue("bank_code", tmpBank.data.data.filter((data : any) => data.bank_code == leaseItemData.bank_code)[0])
            }
        }

        getBank()
    }, [])

    const checkBankCode = (value? : BanksType) => {
        if (value) {
            if (typeof(value) === "object") {
                setIsBankCodeError(false)
            }
            else {
                setIsBankCodeError(true)
            }
        }
        else {
            if (typeof(formik.values["bank_code"]) === "object") {
                setIsBankCodeError(false)
            }
            else {
                setIsBankCodeError(true)
            }
        }
    }

    // about address
    const [isFindAddressModalVisible, setIsFindAddressModalVisible] = useState(false)

    const onSelectAddress = (data : any) => {
        formik.setFieldValue("address", data.address)
        formik.setFieldValue("zip", data.zonecode)
        
        setIsFindAddressModalVisible(false)
    }

    const fieldTitle = (title : string, isRequired : boolean) => {
        return (
            <TableCell
                sx = {[{width : "20%", bgcolor : colors.grey0}]}
            >
                <Box
                    sx = {[styles.alignHorizon]}
                >
                    {
                        isRequired &&
                            <Typography sx = {[fonts.normal_16, {color : colors.red}]}>*</Typography>
                    }
                    <Typography sx = {[fonts.normal_16]}>{title}</Typography>
                </Box>
            </TableCell>
        )
    }

    const onClickCreateLeaseItem = async() => {
        setIsAddConfirmModalVisible(false)
        setIsLoading(true)

        const userToken = await firebaseUserData?.getIdToken();
		const headers = {token: userToken};
		const result = await tokenVerifyAPI(null, headers);

        if (result.status === 200) {
            if (typeof(formik.values["bank_code"]) === "object") {
                let updateLeaseItemData : CreateLeaseItemInterface = {
                    rent_type: formik.values["rent_type"],
                    rent_amount: formik.values["rent_amount"],
                    rent_start_day: moment(formik.values["rent_start_day"]).format("YYYY-MM-DD"),
                    rent_end_day: moment(formik.values["rent_end_day"]).format("YYYY-MM-DD"),
                    address: formik.values["address"],
                    detail_address : formik.values["detail_address"],
                    zip: formik.values["zip"],
                    pay_day: formik.values["pay_day"],
                    receive_type: formik.values["receive_type"],
                    ceo: formik.values["ceo"],
                    phone_one: formik.values["phone_one"] + formik.values["phone_two"],
                    phone_two: formik.values["phone_one"] + formik.values["phone_two"],
                    biz_name: formik.values["biz_name"],
                    biz_no: formik.values["biz_no"],
                    bank_code: formik.values["bank_code"].bank_code,
                    account_no: formik.values["account_no"],
                    account_holder: formik.values["account_holder"],
                }
                
                const updateLeaseItem = await UpdateLeaseItem(headers, leaseItemData?.unique_number, updateLeaseItemData)

                if (updateLeaseItem.status === 200) {
                    naviagtion("/MainPage/LeaseDetailPage", {state : {unique_number : state.unique_number, isUpdateLeaseSuccess : true}})
                }
            }
        }

        setIsLoading(false)
    }
    
    return (
        <div>
            <form onSubmit = {formik.handleSubmit}>
                <Box
                    sx = {[styles.root, {bgcolor : colors.main, minHeight : "100vh"}]}
                    ref = {mainBoxRef}
                >
                    <Container
                        fixed
                    >
                        <Spacer distance = {120}/>

                        <Box
                            sx = {[styles.alignVertical, styles.horizonCenter, {p : 72, bgcolor : colors.white}]}
                        >
                            <Box sx = {[styles.alignHorizon, styles.horizonCenter, styles.spaceBetween, {width : "100%"}]}>
                                <Box sx = {[{flex : 1}]}>
                                    <Button
                                        sx = {[styles.alignHorizon, styles.horizonCenter]}
                                        onClick = {() => setIsBackWarningModalVisible(true)}
                                    >
                                        <ArrowBackIos sx = {{width : 20, height : 20, color : colors.main}}/>
                                        <Spacer distance = {8}/>
                                        <Typography sx = {[fonts.normal_20, {color : colors.main}]}>돌아가기</Typography>
                                    </Button>
                                </Box>
                                <Box sx = {[styles.verticalCenter, {flex : 3}]}>
                                    <Typography sx = {[fonts.bold_24, {fontSize : 36}]}>임대물건 수정</Typography>
                                </Box>
                                <Box sx = {[styles.verticalEnd, {flex : 1}]}>
                                    <Button
                                        variant = {"contained"}
                                        sx = {{width : 174, height : 50}}
                                        disabled = {!formik.isValid || isRentDayIncorrect || isBankCodeError}
                                        onClick = {() => formik.handleSubmit()}
                                    >
                                        <Typography sx = {[fonts.normal_18, {color : colors.white}]}>수정 완료</Typography>
                                    </Button>
                                </Box>
                            </Box>
                            
                            <Spacer distance = {56}/>

                            <Table
                                sx = {[
                                    {borderTop : 2, borderBottom : 2, borderTopColor : colors.black, borderBottomColor : colors.black}
                                ]}
                            >
                                <TableBody>
                                    {/* 임대 구분 */}
                                    <TableRow>
                                        {fieldTitle("임대 구분", true)}
                                        <TableCell
                                            colSpan = {3}
                                        >
                                            <ButtonGroup
                                                color = {"primary"}
                                            >
                                                <Button
                                                    sx = {[
                                                        {width : 124},
                                                        formik.values.rent_type === "HOUSE" &&
                                                            {bgcolor : colors.main}
                                                    ]}
                                                    onClick = {() => formik.setFieldValue("rent_type", "HOUSE")}
                                                >
                                                    <Typography sx = {[
                                                        fonts.normal_16,
                                                        formik.values.rent_type === "HOUSE" &&
                                                            {color : colors.white}
                                                    ]}>
                                                        주택
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    sx = {[
                                                        {width : 124},
                                                        formik.values.rent_type === "STORE" &&
                                                            {bgcolor : colors.main}
                                                    ]}
                                                    onClick = {() => formik.setFieldValue("rent_type", "STORE")}
                                                >
                                                    <Typography sx = {[
                                                        fonts.normal_16,
                                                        formik.values.rent_type === "STORE" &&
                                                            {color : colors.white}
                                                    ]}>
                                                        상가
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    sx = {[
                                                        {width : 124},
                                                        formik.values.rent_type === "OFFICETEL" &&
                                                            {bgcolor : colors.main}
                                                    ]}
                                                    onClick = {() => formik.setFieldValue("rent_type", "OFFICETEL")}
                                                >
                                                    <Typography sx = {[
                                                        fonts.normal_16,
                                                        formik.values.rent_type === "OFFICETEL" &&
                                                            {color : colors.white}
                                                    ]}>
                                                        오피스텔
                                                    </Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>

                                    {/* 임대 금액 */}
                                    <TableRow>
                                        {fieldTitle("임대 금액", true)}
                                        <TableCell
                                            colSpan = {3}
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <TextField
                                                    value = {formik.values["rent_amount"] || ""}
                                                    variant = {"outlined"}
                                                    placeholder = {"최대 200만원"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputSmall]}
                                                    error = {isRentAmountError}
                                                    onChange = {(data) => {
                                                        formik.setFieldValue("rent_amount", data.target.value)
                                                        setIsRentAmountError(Boolean(formik.errors["rent_amount"]))
                                                    }}
                                                    onBlur = {() => setIsRentAmountError(Boolean(formik.errors["rent_amount"]))}
                                                />
                                                <Spacer distance = {8}/>
                                                <Typography sx = {[fonts.normal_16]}>원</Typography>
                                            </Box>
                                            {
                                                isRentAmountError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>임대 금액의 최대값은 200만원입니다.</FormHelperText>
                                            }
                                        </TableCell>
                                    </TableRow>

                                    {/* 임대 시작일, 임대 종료일 */}
                                    <TableRow>
                                        {fieldTitle("임대 시작일", true)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={formik.values["rent_start_day"]}
                                                        onChange = {(date : any) => {
                                                            try {
                                                                if (formik.values["rent_end_day"] && (date.toDate() >= moment(formik.values["rent_end_day"], "YYYYMMDD"))) {
                                                                    formik.setFieldValue("rent_start_day", date.toDate())
                                                                    setIsRentDayIncorrect(true)
                                                                }
                                                                else if (date) {
                                                                    formik.setFieldValue("rent_start_day", date.toDate())
                                                                    setIsRentStartDayError(false)
                                                                    setIsRentDayIncorrect(false)
                                                                }
                                                                else {
                                                                    formik.setFieldValue("rent_start_day", null)
                                                                    setIsRentStartDayError(true)
                                                                    setIsRentDayIncorrect(false)
                                                                }
                                                            }
                                                            catch {
                                                                formik.setFieldValue("rent_start_day", null)
                                                                setIsRentStartDayError(true)
                                                                setIsRentDayIncorrect(false)
                                                            }
                                                        }}
                                                        renderInput = {(params) =>
                                                            <TextField
                                                                {...params}
                                                                size = {"small"}
                                                                sx = {[components.leaseDataInputSmall]}
                                                                error = {isRentStartDayError || isRentDayIncorrect}
                                                                onBlur = {() => setIsRentStartDayError(Boolean(formik.errors["rent_start_day"]))}
                                                            />
                                                        }
                                                        inputFormat = {"YYYY/MM/DD"}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                            {
                                                isRentStartDayError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                            {
                                                isRentDayIncorrect &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>임대 종료일이 임대 시작과 같거나 과거입니다.</FormHelperText>
                                            }
                                        </TableCell>

                                        {/* 임대 종료일 */}
                                        {fieldTitle("임대 종료일", true)}
                                        <TableCell
                                            // colSpan = {3}
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={formik.values["rent_end_day"]}
                                                        onChange = {(date : any) => {
                                                            try {
                                                                if (formik.values["rent_start_day"] && (date.toDate() <= moment(formik.values["rent_start_day"], "YYYYMMDD"))) {
                                                                    formik.setFieldValue("rent_end_day", date.toDate())
                                                                    setIsRentDayIncorrect(true)
                                                                }
                                                                else if (date) {
                                                                    formik.setFieldValue("rent_end_day", date.toDate())
                                                                    setIsRentEndDayError(false)
                                                                    setIsRentDayIncorrect(false)
                                                                }
                                                                else {
                                                                    formik.setFieldValue("rent_end_day", null)
                                                                    setIsRentEndDayError(true)
                                                                    setIsRentDayIncorrect(false)
                                                                }
                                                            }
                                                            catch {
                                                                formik.setFieldValue("rent_end_day", null)
                                                                setIsRentEndDayError(true)
                                                                setIsRentDayIncorrect(false)
                                                            }
                                                        }}
                                                        renderInput = {(params) =>
                                                            <TextField
                                                                {...params}
                                                                size = {"small"}
                                                                sx = {[components.leaseDataInputSmall]}
                                                                error = {isRentEndDayError || isRentDayIncorrect}
                                                                onBlur = {() => setIsRentEndDayError(Boolean(formik.errors["rent_end_day"]))}
                                                            />
                                                        }
                                                        inputFormat = {"YYYY/MM/DD"}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                            {
                                                isRentEndDayError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                            {
                                                isRentDayIncorrect &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>임대 종료일이 임대 시작일보다 과거입니다.</FormHelperText>
                                            }
                                        </TableCell>
                                    </TableRow>

                                    {/* 주소 */}
                                    <TableRow>
                                        {fieldTitle("주소", true)}
                                        <TableCell
                                            colSpan = {3}
                                        >
                                            <Box>
                                                <Box
                                                    sx = {[styles.alignHorizon, styles.horizonCenter]}
                                                >
                                                    <TextField
                                                        variant = {"outlined"}
                                                        placeholder = {"주소 검색"}
                                                        size = {"small"}
                                                        sx = {[components.leaseDataInputLarge, {bgcolor : colors.grey0}]}
                                                        disabled = {true}
                                                        value = {formik.values["address"]}
                                                        error = {isAddressError}
                                                    />
                                                    <Spacer distance = {30}/>
                                                    
                                                    <Button
                                                        variant = {"contained"}
                                                        onClick = {() => setIsFindAddressModalVisible(true)}
                                                    >
                                                        <Typography sx = {[fonts.normal_16, {color : colors.white}]}>검색</Typography>
                                                    </Button>
                                                </Box>
                                                <Spacer distance = {8}/>

                                                <TextField
                                                    variant = {"outlined"}
                                                    placeholder = {"상세 주소 입력"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputLarge]}
                                                    value = {formik.values["detail_address"]}
                                                    error = {isAddressError}
                                                    onChange = {(data) => {
                                                        formik.setFieldValue("detail_address", data.target.value)
                                                        setIsAddressError(Boolean(formik.errors["detail_address"]))
                                                    }}
                                                    onBlur = {() => setIsAddressError(Boolean(formik.errors["detail_address"]))}
                                                />
                                            </Box>
                                            {
                                                isAddressError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                        </TableCell>
                                    </TableRow>

                                    {/* 우편 번호 */}
                                    <TableRow>
                                        {fieldTitle("우편 번호", true)}
                                        <TableCell
                                            colSpan = {3}
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <TextField
                                                    variant = {"outlined"}
                                                    placeholder = {"12345"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputSmall, {bgcolor : colors.grey0}]}
                                                    disabled = {true}
                                                    inputProps = {{maxLength : 5}}
                                                    value = {formik.values["zip"]}
                                                    error = {isZipError}
                                                    onChange = {(data) => formik.setFieldValue("zip", data.target.value)}
                                                    onBlur = {() => setIsZipError(Boolean(formik.errors["zip"]) || !Boolean(Number(formik.values["zip"])))}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>

                                    {/* 납부일, 수납 구분 */}
                                    <TableRow>
                                        {/* 납부일 */}
                                        {fieldTitle("납부일", true)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <FormControl>
                                                    <Select
                                                        sx = {[fonts.normal_16, {width : 280}]}
                                                        size = {"small"}
                                                        value = {formik.values["pay_day"]}
                                                        onChange = {(data) => formik.setFieldValue("pay_day", data.target.value)}
                                                        MenuProps = {{PaperProps : {sx : {maxHeight : 400}}}}
                                                    >
                                                        {
                                                            DayArr.map((data, idx) =>
                                                                <MenuItem key = {idx} value = {data}>{data}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            {
                                                isPayDayError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                        </TableCell>

                                        {/* 수납 구분 */}
                                        {fieldTitle("수납 구분", true)}
                                        <TableCell
                                        >
                                            <ButtonGroup
                                                color = {"primary"}
                                            >
                                                <Button
                                                    sx = {[
                                                        {width : 124},
                                                        formik.values.receive_type === "RECEIVE_FIRST" &&
                                                            {bgcolor : colors.main}
                                                    ]}
                                                    onClick = {() => formik.setFieldValue("receive_type", "RECEIVE_FIRST")}
                                                >
                                                    <Typography sx = {[
                                                        fonts.normal_16,
                                                        formik.values.receive_type === "RECEIVE_FIRST" &&
                                                            {color : colors.white}
                                                    ]}>
                                                        선납
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    sx = {[
                                                        {width : 124},
                                                        formik.values.receive_type === "RECEIVE_LATER" &&
                                                            {bgcolor : colors.main}
                                                    ]}
                                                    onClick = {() => formik.setFieldValue("receive_type", "RECEIVE_LATER")}
                                                >
                                                    <Typography sx = {[
                                                        fonts.normal_16,
                                                        formik.values.receive_type === "RECEIVE_LATER" &&
                                                            {color : colors.white}
                                                    ]}>
                                                        후납
                                                    </Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>

                                    {/* 임대인명, 임대인 전화번호 */}
                                    <TableRow>
                                        {/* 임대인명 */}
                                        {fieldTitle("임대인명", true)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <TextField
                                                    value = {formik.values["ceo"] || ""}
                                                    variant = {"outlined"}
                                                    placeholder = {"임대인명 입력"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputSmall]}
                                                    error = {isCeoError}
                                                    onChange = {(data) => {
                                                        formik.setFieldValue("ceo", data.target.value)
                                                        setIsCeoError(Boolean(formik.errors["ceo"]))
                                                    }}
                                                    onBlur = {() => setIsCeoError(Boolean(formik.errors["ceo"]))}
                                                />
                                            </Box>
                                            {
                                                isCeoError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                        </TableCell>

                                        {/* 임대인 전화번호 */}
                                        {fieldTitle("임대인 전화번호", true)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter, components.leaseDataInputSmall]}
                                            >
                                                <FormControl>
                                                    <Select
                                                        sx = {[fonts.normal_16, {width : 100}]}
                                                        size = {"small"}
                                                        value = {formik.values["phone_one"]}
                                                        onChange = {(data) => formik.setFieldValue("phone_one", data.target.value)}
                                                        MenuProps = {{PaperProps : {sx : {maxHeight : 360}}}}
                                                    >
                                                        {
                                                            PhoneArr.map((data, idx) =>
                                                                <MenuItem key = {idx} value = {data}>{data}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <Spacer distance = {20}/>

                                                <TextField
                                                    value = {formik.values["phone_two"] || ""}
                                                    variant = {"outlined"}
                                                    placeholder = {"숫자 8자리 입력"}
                                                    size = {"small"}
                                                    sx = {{flex : 1}}
                                                    error = {isPhoneTwoError}
                                                    onChange = {(data) => {
                                                        formik.setFieldValue("phone_two", data.target.value)
                                                        setIsPhoneTwoError(Boolean(formik.errors["phone_two"]) || !Boolean(Number(formik.values["phone_two"])))
                                                    }}
                                                    inputProps = {{maxLength : 8}}
                                                    onBlur = {() => setIsPhoneTwoError(Boolean(formik.errors["phone_two"]) || !Boolean(Number(formik.values["phone_two"])))}
                                                />
                                            </Box>
                                            {
                                                isPhoneTwoError &&
                                                    <FormHelperText sx = {[{pl : 120, color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                        </TableCell>
                                    </TableRow>

                                    {/* 상호 (선택), 사업자번호 (선택) */}
                                    <TableRow>
                                        {/* 상호 (선택) */}
                                        {fieldTitle("상호 (선택)", false)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <TextField
                                                    value = {formik.values["biz_name"] || ""}
                                                    variant = {"outlined"}
                                                    placeholder = {"상호 입력"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputSmall]}
                                                    onChange = {formik.handleChange("biz_name")}
                                                />
                                            </Box>
                                        </TableCell>

                                        {/* 사업자번호 (선택) */}
                                        {fieldTitle("사업자번호 (선택)", false)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <TextField
                                                    value = {formik.values["biz_no"] || ""}
                                                    variant = {"outlined"}
                                                    placeholder = {"사업자 번호 입력"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputSmall]}
                                                    onChange = {formik.handleChange("biz_no")}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>

                                    {/* 결제 은행 */}
                                    <TableRow>
                                        {fieldTitle("결제 은행", true)}
                                        <TableCell
                                            colSpan = {3}
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <FormControl>
                                                    <Select
                                                        displayEmpty
                                                        sx = {[fonts.normal_16, components.leaseDataInputSmall]}
                                                        size = {"small"}
                                                        value = {formik.values["bank_code"]}
                                                        onChange = {(data : any) => {
                                                                formik.setFieldValue("bank_code", data.target.value)
                                                                checkBankCode(data.target.value)
                                                            }}
                                                        error = {isBankCodeError}
                                                        onBlur = {() => checkBankCode()}
                                                        
                                                    >
                                                        <MenuItem disabled value = {""}>결제 은행 선택</MenuItem>
                                                        {
                                                            bankList.map((data : any, idx : number) => 
                                                                <MenuItem key = {idx} value = {data}>{data.bank_name}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            {
                                                isBankCodeError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 선택 항목입니다.</FormHelperText>
                                            }
                                        </TableCell>
                                    </TableRow>

                                    {/* 결제 은행번호, 결제 계좌주명 */}
                                    <TableRow>
                                        {/* 결제 은행번호 */}
                                        {fieldTitle("결제 은행번호", true)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <TextField
                                                    value = {formik.values["account_no"] || ""}
                                                    variant = {"outlined"}
                                                    placeholder = {"결제 은행번호 입력"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputSmall]}
                                                    error = {isAccountNoError}
                                                    onChange = {(data) => {
                                                        formik.setFieldValue("account_no", data.target.value)
                                                        setIsAccountNoError(Boolean(formik.errors["account_no"]))
                                                    }}
                                                    onBlur = {() => setIsAccountNoError(Boolean(formik.errors["account_no"]))}
                                                />
                                            </Box>
                                            {
                                                isAccountNoError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                        </TableCell>

                                        {/* 결제 계좌주명 */}
                                        {fieldTitle("결제 계좌주명", true)}
                                        <TableCell
                                        >
                                            <Box
                                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                            >
                                                <TextField
                                                    value = {formik.values["account_holder"] || ""}
                                                    variant = {"outlined"}
                                                    placeholder = {"결제 계좌주명 입력"}
                                                    size = {"small"}
                                                    sx = {[components.leaseDataInputSmall]}
                                                    error = {isAccountHolderError}
                                                    onChange = {(data) => {
                                                        formik.setFieldValue("account_holder", data.target.value)
                                                        setIsAccountHolderError(Boolean(formik.errors["account_holder"]))
                                                    }}
                                                    onBlur = {() => setIsAccountHolderError(Boolean(formik.errors["account_holder"]))}
                                                />
                                            </Box>
                                            {
                                                isAccountHolderError &&
                                                    <FormHelperText sx = {[{color : colors.red}]}>필수 입력 항목입니다.</FormHelperText>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Spacer distance = {46}/>
                        </Box>
                        <Spacer distance = {120}/>
                    </Container>

                    {/* 우편번호 검색 modal */}
                    <Modal
                        open = {isFindAddressModalVisible}
                    >
                        <Box
                            sx = {{
                                position : "absolute",
                                top : "50%",
                                left : "50%",
                                transform : "translate(-50%, -50%)",
                                width : 500,
                                backgroundColor : "#fff"
                            }}
                        >
                            <Box
                                sx = {[
                                    styles.verticalEnd,
                                ]}
                            >
                                <IconButton
                                    onClick = {() => setIsFindAddressModalVisible(false)}
                                >
                                    <Close sx = {{width : 24, height : 24, color : colors.grey2}}/>
                                </IconButton>
                            </Box>
                            <DaumPostcode
                                onComplete = {(data) => onSelectAddress(data)}
                            />
                        </Box>
                    </Modal>

                    {/* 물건 등록시 확인 modal */}
                    <Modal
                        open = {isAddConfirmModalVisible}
                        onClose = {((event, reason) => {reason == "backdropClick" && setIsAddConfirmModalVisible(false)})}
                    >
                        <Box
                            sx = {{
                                position : "absolute",
                                top : "50%",
                                left : "50%",
                                transform : "translate(-50%, -50%)",
                                width : "60vw",
                                backgroundColor : "#fff",
                                p : 40
                            }}
                        >
                            <Box sx = {[styles.alignHorizon, styles.horizonCenter]}>
                                <Check sx = {{width : 32, height : 32, color : colors.green}}/>
                                <Spacer distance = {16}/>
                                <Typography sx = {[fonts.bold_20]}>임대 물건 수정 확인</Typography>
                            </Box>
                            <Spacer distance = {16}/>

                            <Box>
                                <Typography sx = {[fonts.normal_18]}>다음 내용으로 임대 물건이 수정됩니다.</Typography>
                                <Spacer distance = {8}/>
                                <Box sx = {{p : 16, bgcolor : colors.grey0}}>
                                    <Typography sx = {[fonts.normal_16]}>임대 구분 : {Translator[formik.values["rent_type"]]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>임대 금액 : {formik.values["rent_amount"]}원</Typography>
                                    <Typography sx = {[fonts.normal_16]}>임대 시작일 : {moment(formik.values["rent_start_day"]).format("YYYY-MM-DD")}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>임대 종료일 : {moment(formik.values["rent_end_day"]).format("YYYY-MM-DD")}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>주소 : {formik.values["address"]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>상세주소 : {formik.values["detail_address"]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>우편 번호 : {formik.values["zip"]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>납부일 : 매월 {formik.values["pay_day"]}일</Typography>
                                    <Typography sx = {[fonts.normal_16]}>수납 구분 : {Translator[formik.values["receive_type"]]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>입대인명 : {formik.values["ceo"]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>임대인 전화번호 : {formik.values["phone_one"]}-{formik.values["phone_two"]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>상호 : {formik.values["biz_name"] ? formik.values["biz_name"] : "-"}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>사업자번호 : {formik.values["biz_no"] ? formik.values["biz_no"] : "-"}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>결제 은행 : {typeof(formik.values["bank_code"]) === "object" && formik.values["bank_code"].bank_name}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>결제 은행번호 : {formik.values["account_no"]}</Typography>
                                    <Typography sx = {[fonts.normal_16]}>결제 계좌주명 : {formik.values["account_holder"]}</Typography>
                                </Box>
                            </Box>
                            <Spacer distance = {62}/>

                            <Box sx = {[styles.alignHorizon, styles.verticalEnd, {width : "100%"}]}>
                                <Button
                                    variant = {"outlined"}
                                    onClick = {() => setIsAddConfirmModalVisible(false)}
                                >
                                    <Typography sx = {[fonts.normal_16]}>취소</Typography>
                                </Button>
                                <Spacer distance = {8}/>

                                <Button
                                    variant = {"contained"}
                                    onClick = {onClickCreateLeaseItem}
                                >
                                    <Typography sx = {[fonts.normal_16, {color : colors.white}]}>수정 완료</Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

                    {/* 돌아가기 클릭시 경고 modal */}
                    <Modal
                        open = {isBackWarningModalVisible}
                        onClose = {((event, reason) => {reason == "backdropClick" && setIsBackWarningModalVisible(false)})}
                    >
                        <Box
                            sx = {{
                                position : "absolute",
                                top : "50%",
                                left : "50%",
                                transform : "translate(-50%, -50%)",
                                width : "60vw",
                                backgroundColor : "#fff",
                                p : 40
                            }}
                        >
                            <Box sx = {[styles.alignHorizon, styles.horizonCenter]}>
                                <WarningAmberOutlined sx = {{width : 32, height : 32, color : colors.red}}/>
                                <Spacer distance = {16}/>
                                <Typography sx = {[fonts.bold_20]}>주의!</Typography>
                            </Box>
                            <Spacer distance = {16}/>

                            <Box>
                                <Typography sx = {[fonts.normal_16]}>임대 물건 수정을 취소하고 조회 화면으로 돌아가시겠습니까?</Typography>
                                <Typography sx = {[fonts.normal_16]}>입력된 내용이 전부 사라집니다.</Typography>
                            </Box>
                            <Spacer distance = {62}/>

                            <Box sx = {[styles.alignHorizon, styles.verticalEnd, {width : "100%"}]}>
                                <Button
                                    variant = {"outlined"}
                                    onClick = {() => naviagtion("/MainPage/LeaseDetailPage", {state : {unique_number : state.unique_number, isUpdateLeaseCancel : true}})}
                                >
                                    <Typography sx = {[fonts.normal_16]}>조회 화면으로 돌아가기</Typography>
                                </Button>
                                <Spacer distance = {8}/>

                                <Button
                                    variant = {"contained"}
                                    onClick = {() => setIsBackWarningModalVisible(false)}
                                >
                                    <Typography sx = {[fonts.normal_16, {color : colors.white}]}>임대 물건 수정 계속하기</Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            </form>

            {/* loading */}
            {
                isLoading &&
                    <Box>
                        <Box sx = {[{position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight, bgcolor : colors.black, opacity : 0.5}]}/>
                        <Box sx = {[styles.horizonCenter, styles.verticalCenter, {position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight}]}>
                            <Box sx = {[styles.alignVertical, styles.horizonCenter, styles.verticalCenter, {width : "40%", height : 180, bgcolor : colors.white}]}>
                                <Typography sx = {[fonts.bold_16]}>잠시만 기다려주세요.</Typography>
                                <Typography sx = {[fonts.normal_16]}>임대 물건 수정 내용 반영중...</Typography>
                                <Spacer distance = {24}/>
                                <RotateRight sx = {[
                                    {width : 42, height : 42, color : colors.green},
                                    {
                                        animation: "spin 2s linear infinite",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(0deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(360deg)",
                                            },
                                        },
                                    }
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
            }
        </div>
    )
}

export default LeaseUpdatePage