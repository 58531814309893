export const colors = {
    main : "#183377",
    grey0 : '#F0F0F0',
    grey1 : '#ECECEC',
	grey2 : '#8F8F8F',
    grey3 : '#7A7A7A',
    white : "#ffffff",
    black : "#000000",
    green : "#03C75A",
    red : "#ED0000",
    orange : "#F37F37",
}

export const fonts = {
    normal_14 : {
        fontFamily : "regular",
        fontSize : 14,
        color : colors.black
    },
    normal_16 : {
        fontFamily : "regular",
        fontSize : 16,
        color : colors.black
    },
    normal_18 : {
        fontFamily : "regular",
        fontSize : 18,
        color : colors.black
    },
    normal_20 : {
        fontFamily : "regular",
        fontSize : 20,
        color : colors.black
    },
    normal_24 : {
        fontFamily : "regular",
        fontSize : 24,
        color : colors.black
    },
    bold_14 : {
        fontFamily : "bold",
        fontSize : 14,
        color : colors.black
    },
    bold_16 : {
        fontFamily : "bold",
        fontSize : 16,
        color : colors.black
    },
    bold_18 : {
        fontFamily : "bold",
        fontSize : 18,
        color : colors.black
    },
    bold_20 : {
        fontFamily : "bold",
        fontSize : 20,
        color : colors.black
    },
    bold_24 : {
        fontFamily : "bold",
        fontSize : 24,
        color : colors.black
    },
}

export const styles = {
    root : {
        display : "flex",
        flex : 1,
        margin : 0,
        padding : 0,
    },
    flexBox : {
        display : "flex"
    },
    alignHorizon : {
        display : "flex",
        flexDirection : "row"
    },
    alignVertical : {
        display : "flex",
        flexDirection : "column"
    },
    horizonCenter : {
        display : "flex",
        alignItems : "center"
    },
    horizonEnd : {
        display : "flex",
        alignItems : "flex-end"
    },
    verticalStart : {
        display : "flex",
        justifyContent : "flex-start"
    },
    verticalCenter : {
        display : "flex",
        justifyContent : "center"
    },
    verticalEnd : {
        display : "flex",
        justifyContent : "flex-end"
    },
    spaceBetween : {
        display : "flex",
        justifyContent : "space-between"
    },
}

export const components = {
    headerTab : {
        width : 174,
        height : 56,
        borderTopLeftRadius : 8,
        borderTopRightRadius : 8
    },
    textInput : {
        padding : 20,
        borderRadius : 2,
        border : "1px solid red"
    },
    leaseDataInputSmall : {
        width : 280
    },
    leaseDataInputLarge : {
        width : 480
    }
}