import * as yup from "yup"

export const LeaseAddValidationSchema = yup.object().shape({
    rent_type : yup
        .string()
        .matches(/(HOUSE|STORE|OFFICETEL)/)
        .required(),
    rent_amount : yup
        .number()
        .min(1)
        .max(2000000)
        .required(),
    rent_start_day : yup
        .string()
        .required(),
    rent_end_day : yup
        .string()
        .required(),
    address : yup
        .string()
        .required(),
    detail_address : yup
        .string()
        .required(),
    zip : yup
        .string()
        .length(5)
        .required(),
    pay_day : yup
        .string()
        .matches(/(01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31)/)
        .required(),
    receive_type : yup
        .string()
        .matches(/(RECEIVE_FIRST|RECEIVE_LATER)/)
        .required(),
    ceo : yup
        .string()
        .required(),
    phone_one : yup
        .string()
        .matches(/(010|011|016|017|019)/)
        .required(),
    phone_two : yup
        .string()
        .length(8)
        .required(),
    biz_name : yup
        .string(),
    biz_no : yup
        .string(),
    bank_code : yup
        .object({
            _id: yup.string(),
            bank_code: yup.number(),
            bank_name: yup.string(),
            bank_url: yup.string(),
            swift_code: yup.string(),
            bank_order: yup.number(),
            createdAt: yup.date(),
            updatedAt: yup.date(),
        })
        .required(),
    account_no : yup
        .number()
        .required(),
    account_holder : yup
        .string()
        .required(),
})