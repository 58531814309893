// react
import React, { useState, useLayoutEffect } from "react";
import './App.css'

import { BrowserRouter, Routes, Route } from 'react-router-dom';


// material ui
import {
    createTheme,
    ThemeProvider,
    CssBaseline,
    Container,
    Box,
    Typography,
} from "@mui/material";

// library
import { auth } from "./Firebase";
import { onAuthStateChanged } from "firebase/auth";

// components

// pages
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import LeaseAddPage from "./pages/LeaseAddPage";
import LeaseDetailPage from "./pages/LeaseDetailPage";
import LeaseUpdatePage from "./pages/LeaseUpdatePage";

// styles
import { colors, fonts, styles, components } from "./styles";

// api

// images


const theme = createTheme({
    spacing: 1,
    palette: {
        primary: {
            main: colors.main
        },
        secondary: {
            main: colors.grey1,
        }
    },
    components: {
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
})

function App() {
    // const navigation = useNavigate()

    const [userData, setUserData] = useState<any>(null)

    useLayoutEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserData(user)
                // navigation("/MainPage")
            } else {
                // navigation("/LoginPage")
            }
        });
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <BrowserRouter>
                <Routes>
                    <Route
                        path={"/"}
                        element={<LoginPage />}
                    />

                    {
                        userData == null ?
                            <>
                                <Route
                                    path={"/LoginPage"}
                                    element={<LoginPage />}
                                />
                            </> :
                            <>
                                <Route
                                    path={"/MainPage"}
                                    element={<MainPage />}
                                />
                                <Route
                                    path={"/MainPage/LeaseAddPage"}
                                    element={<LeaseAddPage />}
                                />
                                <Route
                                    path={"/MainPage/LeaseDetailPage"}
                                    element={<LeaseDetailPage />}
                                />
                                <Route
                                    path={"/MainPage/LeaseUpdatePage"}
                                    element={<LeaseUpdatePage />}
                                />
                            </>
                    }
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;