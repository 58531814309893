// react
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// material ui
import {
    Container,
    Box,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
} from "@mui/material";

// library
import { auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";

// components
import { Spacer } from "../components/Spacer";
import { ToastMessage } from "../components/ToastMessage";

// pages

// interfaces
import { GetLeaseItemInterface, BanksType } from "../utils/Interfaces";

// styles
import { colors, fonts, styles, components } from "../styles";

// api
import { tokenVerifyAPI, GetLeaseItemDetail, GetBanks } from "../utils/APIs";

// images
import { ArrowBackIos, RotateRight } from "@mui/icons-material";

// utils
import translator from "../utils/Translator.json"


const Translator : any = translator.translator

const LeaseDetailPage = () => {
    const naviagtion = useNavigate()
    const { state } = useLocation()

    const [isLoading, setIsLoading] = useState(true)
    const mainBoxRef : any = useRef()

    // get user data
    const [firebaseUserData, setFirebaseUserData] = useState<any>()
    const [userData, setUserData] = useState<any>()

    const getUserData = async(userData : any) => {
        const userToken = await userData.getIdToken();
		const headers = {token: userToken};
		const result = await tokenVerifyAPI(null, headers);

        if (result.status === 200) {
            setUserData(result.data.data)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setFirebaseUserData(user)
                getUserData(user)
            }
        });
        setIsLoading(false)
    }, [])

    const [leaseItemData, setLeaseItemData] = useState<GetLeaseItemInterface>()
    const [bankList, setBankList] = useState<BanksType[]>([])

    const [isToastMessageVisible, setIsToastMessageVisible] = useState(false)
    const [toastType, setToastType] = useState<"error" | "warn" | "success">("success")
    const [toastMessage, setToastMessage] = useState("")
    
    useEffect(() => {
        const getLeaseItemDetail = async() => {
            setIsLoading(true)

            const tmpBank = await GetBanks()

            if (tmpBank.status === 200) {
                setBankList(tmpBank.data.data)
            }
            
            const userToken = await firebaseUserData?.getIdToken();
            const headers = {token: userToken};
            const result = await tokenVerifyAPI(null, headers);

            if (result.status === 200) {
                const tmpLeaseItemDetail = await GetLeaseItemDetail(headers, state.unique_number)

                if (tmpLeaseItemDetail.status === 200) {
                    setLeaseItemData(tmpLeaseItemDetail.data.data)
                }
            }
            setIsLoading(false)
        }

        if (firebaseUserData) getLeaseItemDetail()
    }, [firebaseUserData])

    useEffect(() => {
        if (state?.isUpdateLeaseCancel) {
            setToastType("warn")
            setToastMessage("임대 물건 수정이 취소되었습니다.")
            setIsToastMessageVisible(true)
        }
        if (state?.isUpdateLeaseSuccess) {
            setToastType("success")
            setToastMessage("정상적으로 수정되었습니다.")
            setIsToastMessageVisible(true)
        }
    }, [state])

    const fieldTitle = (title : string) => {
        return (
            <TableCell
                sx = {[{width : "20%", bgcolor : colors.grey0}]}
            >
                <Box
                    sx = {[styles.alignHorizon]}
                >
                    <Typography sx = {[fonts.normal_16]}>{title}</Typography>
                </Box>
            </TableCell>
        )
    }

    const onPressUpdate = () => {
        if (moment(leaseItemData?.createdAt).format("YYYYMMDD") === moment().format("YYYYMMDD")){
            naviagtion("/MainPage/LeaseUpdatePage", {state : {unique_number : state.unique_number, leaseItemData : leaseItemData}})
        }
        else {
            setToastType("error")
            setToastMessage("오늘 등록된 물건만 수정할 수 있습니다.")
            setIsToastMessageVisible(true)
        }
    }

    return (
        <Box
            sx = {[styles.root, {bgcolor : colors.main, minHeight : "100vh"}]}
            ref = {mainBoxRef}
        >
            <Container
                fixed
            >
                <Spacer distance = {120}/>

                <Box
                    sx = {[styles.alignVertical, styles.horizonCenter, {p : 72, bgcolor : colors.white}]}
                >
                    <Box sx = {[styles.alignHorizon, styles.horizonCenter, styles.spaceBetween, {width : "100%"}]}>
                        <Box sx = {[{flex : 1}]}>
                            <Button
                                sx = {[styles.alignHorizon, styles.horizonCenter]}
                                onClick = {() => naviagtion("/MainPage")}
                            >
                                <ArrowBackIos sx = {{width : 20, height : 20, color : colors.main}}/>
                                <Spacer distance = {8}/>
                                <Typography sx = {[fonts.normal_20, {color : colors.main}]}>돌아가기</Typography>
                            </Button>
                        </Box>
                        <Box sx = {[styles.verticalCenter, {flex : 3}]}>
                            <Typography sx = {[fonts.bold_24, {fontSize : 36}]}>임대물건 조회</Typography>
                        </Box>
                        <Box sx = {[styles.verticalEnd, {flex : 1}]}>
                            <Button
                                variant = {"contained"}
                                sx = {{width : 174, height : 50}}
                                onClick = {onPressUpdate}
                            >
                                <Typography sx = {[fonts.normal_18, {color : colors.white}]}>정보 수정</Typography>
                            </Button>
                        </Box>
                    </Box>
                    
                    <Spacer distance = {56}/>

                    <Table
                        sx = {[
                            {borderTop : 2, borderBottom : 2, borderTopColor : colors.black, borderBottomColor : colors.black}
                        ]}
                    >
                        <TableBody>
                            {/* 임대 구분 */}
                            <TableRow>
                                {fieldTitle("임대 구분")}
                                <TableCell
                                    colSpan = {3}
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && Translator[leaseItemData.rent_type]}</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 임대 금액 */}
                            <TableRow>
                                {fieldTitle("임대 금액")}
                                <TableCell
                                    colSpan = {3}
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.rent_amount}원</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 임대 시작일 */}
                            <TableRow>
                                {fieldTitle("임대 시작일")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && moment(leaseItemData.rent_start_day, "YYYYMMDD").format("YYYY.MM.DD")}</Typography>
                                </TableCell>

                                {fieldTitle("임대 종료일")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && moment(leaseItemData.rent_end_day, "YYYYMMDD").format("YYYY.MM.DD")}.</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 주소 */}
                            <TableRow>
                                {fieldTitle("주소")}
                                <TableCell
                                    colSpan = {3}
                                >
                                    <Box>
                                        <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.address}</Typography>
                                        <Spacer distance = {8}/>

                                        <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.detail_address}</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>

                            {/* 우편 번호 */}
                            <TableRow>
                                {fieldTitle("우편 번호")}
                                <TableCell
                                    colSpan = {3}
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.zip}</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 납부일, 수납 구분 */}
                            <TableRow>
                                {/* 납부일 */}
                                {fieldTitle("납부일")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>매월 {leaseItemData && leaseItemData.pay_day}일</Typography>
                                </TableCell>

                                {/* 수납 구분 */}
                                {fieldTitle("수납 구분")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && Translator[leaseItemData.receive_type]}</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 임대인명, 임대인 전화번호 */}
                            <TableRow>
                                {/* 임대인명 */}
                                {fieldTitle("임대인명")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.ceo}</Typography>
                                </TableCell>

                                {/* 임대인 전화번호 */}
                                {fieldTitle("임대인 전화번호")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.phone_one}</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 상호 (선택), 사업자번호 (선택) */}
                            <TableRow>
                                {/* 상호 (선택) */}
                                {fieldTitle("상호")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && (leaseItemData.biz_name ? leaseItemData.biz_name : "-")}</Typography>
                                </TableCell>

                                {/* 사업자번호 (선택) */}
                                {fieldTitle("사업자번호")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && (leaseItemData.biz_no ? leaseItemData.biz_no : "-")}</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 결제 은행 */}
                            <TableRow>
                                {fieldTitle("결제 은행")}
                                <TableCell
                                    colSpan = {3}
                                >
                                    <Typography sx = {[fonts.normal_16]}>{bankList && bankList.filter((data : any) => data.bank_code == leaseItemData?.bank_code)[0]?.bank_name}</Typography>
                                </TableCell>
                            </TableRow>

                            {/* 결제 은행번호, 결제 계좌주명 */}
                            <TableRow>
                                {/* 결제 은행번호 */}
                                {fieldTitle("결제 은행번호")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.account_no}</Typography>
                                </TableCell>

                                {/* 결제 계좌주명 */}
                                {fieldTitle("결제 계좌주명")}
                                <TableCell
                                >
                                    <Typography sx = {[fonts.normal_16]}>{leaseItemData && leaseItemData.account_holder}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Spacer distance = {46}/>
                </Box>
                <Spacer distance = {120}/>
            </Container>

            {/* loading */}
            {
                isLoading &&
                    <Box>
                        <Box sx = {[{position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight, bgcolor : colors.black, opacity : 0.5}]}/>
                        <Box sx = {[styles.horizonCenter, styles.verticalCenter, {position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight}]}>
                            <Box sx = {[styles.alignVertical, styles.horizonCenter, styles.verticalCenter, {width : "40%", height : 180, bgcolor : colors.white}]}>
                                <Typography sx = {[fonts.bold_16]}>잠시만 기다려주세요.</Typography>
                                <Typography sx = {[fonts.normal_16]}>임대 물건 조회중...</Typography>
                                <Spacer distance = {24}/>
                                <RotateRight sx = {[
                                    {width : 42, height : 42, color : colors.green},
                                    {
                                        animation: "spin 2s linear infinite",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(0deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(360deg)",
                                            },
                                        },
                                    }
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
            }

            <ToastMessage
                isVisible = {isToastMessageVisible}
                type = {toastType}
                title = {toastMessage}
                onClose = {() => setIsToastMessageVisible(false)}
                onPressCloseButton = {() => setIsToastMessageVisible(false)}
            />
        </Box>
    )
}

export default LeaseDetailPage