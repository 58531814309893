// react
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// material ui
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel
} from "@mui/material";

// library
import { auth } from "../Firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useCookies } from "react-cookie";

// components
import { Spacer } from "../components/Spacer";

// pages

// interfaces

// styles
import { colors, fonts, styles, components } from "../styles";

// api

// images
import { ReactComponent as BOHOME_LOGO } from "../assets/images/bohome_logo.svg"
import { RotateRight } from "@mui/icons-material";

// utils


const LoginPage = () => {
    const navigation = useNavigate()
    const { state } = useLocation()

    const [isLoading, setIsLoading] = useState(false)

    const mainBoxRef : any = useRef()

    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const [isLoginError, setIsLoginError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId"])
    const [isRememberId, setIsRememberId] = useState(false)

    useEffect(() => {
        if (cookies.rememberUserId !== undefined) {
            setUserEmail(cookies.rememberUserId)
            setIsRememberId(true)
        }
    }, [])

    const onChangeRememberId = () => {
        if(isRememberId) removeCookie("rememberUserId")
        setIsRememberId(!isRememberId)
    }

    const onClickLoginButton = async() => {
        setIsLoading(true)

        await signInWithEmailAndPassword(auth, userEmail, userPassword)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                // ...

                if (isRememberId) setCookie("rememberUserId", userEmail)
                navigation("/MainPage")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                setIsLoginError(true)

                switch (errorCode) {
                    case "auth/invalid-email" :
                        setErrorMessage("이메일 형식이 올바르지 않습니다.")
                        break
                    case "auth/user-not-found" :
                        setErrorMessage("계정 정보를 찾을 수 없습니다.")
                        break
                    default :
                        setErrorMessage("아이디, 비밀번호를 확인해주세요.")
                        break
                }
            });
        
        setIsLoading(false)
    }

    return (
        <Box
            ref = {mainBoxRef}
        >
            <Box
                sx = {[styles.root, styles.alignVertical, {bgcolor : colors.main, minHeight : "100vh"}]}
            >
                <Container
                        fixed
                        sx = {[styles.alignVertical, styles.horizonCenter, styles.verticalCenter]}
                    >
                    <Spacer distance = {120}/>

                    <Box
                        sx = {[]}
                    >
                        <BOHOME_LOGO width = {192} height = {58}/>
                    </Box>
                    <Spacer distance = {60}/>

                    <Box
                        sx = {[styles.alignVertical, styles.horizonCenter, {width : 990, px : 102, py : 64, bgcolor : colors.white}]}
                    >
                        <TextField
                            fullWidth
                            value = {userEmail}
                            variant = {"outlined"}
                            label = {"아이디"}
                            onChange = {(data) => setUserEmail(data.target.value)}
                            error = {isLoginError}
                        />
                        <Spacer distance = {28}/>

                        <TextField
                            fullWidth
                            variant = {"outlined"}
                            label = {"비밀번호"}
                            type = {"password"}
                            onChange = {(data) => setUserPassword(data.target.value)}
                            error = {isLoginError}
                            onKeyDown = {(data) => data.key === "Enter" && onClickLoginButton()}
                        />
                        <Spacer distance = {14}/>

                        {
                            isLoginError &&
                                <Box sx = {{width : "100%"}}>
                                    <Typography sx = {[fonts.normal_16, {color : colors.red}]}>{errorMessage}</Typography>
                                </Box>
                        }
                        <Spacer distance = {14}/>

                        <Box sx = {[styles.alignHorizon, styles.horizonCenter, {width : "100%"}]}>
                            <FormGroup>
                                <FormControlLabel
                                    control = {
                                        <Checkbox
                                            checked = {isRememberId}
                                            onChange = {onChangeRememberId}
                                        />
                                    }
                                    label = {"아이디 저장"}
                                />
                            </FormGroup>
                        </Box>
                        <Spacer distance = {28}/>

                        <Button
                            variant = {"contained"}
                            sx = {{width : 174, height : 50}}
                            disabled = {userEmail === "" || userPassword === ""}
                            onClick = {onClickLoginButton}
                        >
                            <Typography sx = {[fonts.normal_18, {color : colors.white}]}>로그인</Typography>
                        </Button>
                    </Box>
                    <Spacer distance = {120}/>
                </Container>
            </Box>

            {/* loading */}
            {
                isLoading &&
                    <Box>
                        <Box sx = {[{position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight, bgcolor : colors.black, opacity : 0.5}]}/>
                        <Box sx = {[styles.horizonCenter, styles.verticalCenter, {position : "absolute", left : 0, top : 0, width : "100%", height : mainBoxRef.current?.clientHeight}]}>
                            <Box sx = {[styles.alignVertical, styles.horizonCenter, styles.verticalCenter, {width : "40%", height : 180, bgcolor : colors.white}]}>
                                <Typography sx = {[fonts.bold_16]}>잠시만 기다려주세요.</Typography>
                                <Typography sx = {[fonts.normal_16]}>로그인중...</Typography>
                                <Spacer distance = {24}/>
                                <RotateRight sx = {[
                                    {width : 42, height : 42, color : colors.green},
                                    {
                                        animation: "spin 2s linear infinite",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(0deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(360deg)",
                                            },
                                        },
                                    }
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
            }
        </Box>
    )
}

export default LoginPage;